<template>
  <div class="refund">
    <div class="title"> <span>对公提现</span> <span @click="$router.push('/points/withdraw-history')"><i class="el-icon-time"></i>历史记录</span></div>
    <div class="table-box">
      <div class="row"><el-input v-model="expressid" placeholder="请输入运单号"></el-input><el-button type="primary" @click="search">搜索</el-button></div>
      <el-table :data="currentList"  style="width: 100%" :header-cell-style="{textAlign: 'center', color: '#333333'}" :cell-style="{ textAlign: 'center',color: '#555555' }">
        <el-table-column label="流水号" width="80">
          <template slot-scope="scope"><span @click="copy('id', scope.row.id)">{{scope.row.id}}</span> <textarea id="id"></textarea></template>
        </el-table-column>
        <el-table-column label="用户名" width="110">
          <template slot-scope="scope"><span @click="copy('teamowner', scope.row.teamowner)">{{scope.row.teamowner}}</span> <textarea id="teamowner"></textarea></template>
        </el-table-column>
        <el-table-column label="提现数(泛函币)" width="120">
          <template slot-scope="scope"><span @click="copy('points', scope.row.points)">{{scope.row.points}}</span> <textarea id="points"></textarea></template>
        </el-table-column>
        <el-table-column label="提现数(人民币)" width="120">
          <template slot-scope="scope"><span @click="copy('rmb', disponse(scope.row.points))">{{disponse(scope.row.points)}}</span> <textarea id="rmb"></textarea></template>
        </el-table-column>
        <el-table-column label="户名" width="150">
          <template slot-scope="scope"><span @click="copy('accountname', scope.row.accountname)">{{scope.row.accountname}}</span> <textarea id="accountname"></textarea></template>
        </el-table-column>
        <el-table-column label="开户行" width="200">
          <template slot-scope="scope"><span @click="copy('bank', scope.row.bank)">{{scope.row.bank}}</span> <textarea id="bank"></textarea></template>
        </el-table-column>
        <el-table-column label="银行账号" width="200">
          <template slot-scope="scope"><span @click="copy('accountid', scope.row.accountid)">{{scope.row.accountid}}</span> <textarea id="accountid"></textarea></template>
        </el-table-column>
        <el-table-column label="联系电话" width="180">
          <template slot-scope="scope"><span @click="copy('phone', scope.row.phone)">{{scope.row.phone}}</span> <textarea id="phone"></textarea></template>
        </el-table-column>
        <el-table-column label="快递公司" width="180">
          <template slot-scope="scope"><span @click="copy('express', scope.row.express)">{{scope.row.express}}</span> <textarea id="express"></textarea></template>
        </el-table-column>
        <el-table-column label="快递单号" width="180">
          <template slot-scope="scope"><span @click="copy('expressid', scope.row.expressid)">{{scope.row.expressid}}</span> <textarea id="expressid"></textarea></template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" class="hit" size="mini" @click="gotoHitMoney(scope.row)">打款</el-button>
            <el-button type="danger" class="refuse"  size="mini" @click="refuseMoney(scope.row)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="records.length > 0" :page-size="10" @current-change="currentChange" layout="prev, pager, next" :total="records.length"></el-pagination>
    </div>
    <div class="popout" v-if="popoutFlag">
    <div class="popout_box hit" v-if="hitFlag">
      <div class="popout_title">打款确认</div>
      <div class="popout_body">
        <div class="eleNumber"><el-input v-model="eleNumber" placeholder="输入电子回单号码"></el-input></div>
        <div class="errtip"><span  v-show="emptyHit">请输入回执单号</span></div>
        <p>支付金额： <span>{{disponse(saveRow.points)}}</span> ¥</p>
        <div class="btns">
          <el-button size="small" class="back" @click="onBack" >返回</el-button>
          <el-button size="small" class="sure" @click="onConfirm"  type="primary">确认</el-button></div>
      </div>
    </div>
    <div class="popout_box refuseFlag" v-if="refuseFlag">
      <div class="popout_title">拒绝理由</div>
      <div class="popout_body">
        <el-input type="textarea" :rows="2" resize="none" v-model="reason"></el-input>
        <div class="btns">
          <el-button size="small" class="back" @click="refuseBack" >返回</el-button>
          <el-button size="small" class="sure" @click="refuseConfirm" type="primary">确认</el-button></div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { teamerDrawlog, dealteamdraw } from '@/api/withdraw.js'

export default {
  name: '对公提现',
  data () {
    return {
      expressid: '',
      records: [],
      currentList: [],
      page: 1,
      saveRow: {},
      popoutFlag: false,
      hitFlag: false,
      eleNumber: '',
      refuseFlag: false,
      reason: '您的提现申请信息填写有误，请您核对信息后再次提交申请',
      emptyHit: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await teamerDrawlog()
      if (res.code === 0) {
        this.records = res.records
        this.records = this.records.filter(e => {
          if (this.expressid) {
            return e.status === 0 && e.expressid === this.expressid
          } else {
            return e.status === 0
          }
        })
        this.currentList = this.records.slice((this.page - 1) * 10, this.page * 10)
      }
    },
    search () {
      this.page = 1
      this.getList()
    },
    currentChange (p) {
      this.page = p
      this.getList()
    },
    disponse (n) {
      return n / 100
    },
    gotoHitMoney (row) {
      this.saveRow = row
      this.popoutFlag = true
      this.hitFlag = true
    },
    onBack () {
      this.saveRow = {}
      this.popoutFlag = false
      this.hitFlag = false
      this.emptyHit = false
    },
    onConfirm () {
      if (this.eleNumber === '') {
        this.emptyHit = true
        return false
      } else {
        this.emptyHit = false
        const data = {
          drawid: this.saveRow.id,
          transno: this.eleNumber,
          passornot: 1
        }
        dealteamdraw(data).then(res => {
          if (res.code === 0) {
            this.$notify.success({
              title: '成功',
              message: '申请成功',
              duration: 500
            })
            this.eleNumber = ''
            this.onBack()
            this.getList()
          }
        })
      }
    },
    refuseMoney (row) {
      this.saveRow = row
      this.popoutFlag = true
      this.refuseFlag = true
    },
    refuseBack () {
      this.saveRow = {}
      this.popoutFlag = false
      this.refuseFlag = false
    },
    refuseConfirm () {
      const data = {
        drawid: this.saveRow.id,
        comment: this.reason,
        passornot: -1
      }
      dealteamdraw(data).then(res => {
        if (res.code === 0) {
          this.$notify.success({
            title: '成功',
            message: '拒绝成功',
            duration: 500
          })
          this.reason = ''
          this.refuseBack()
          this.getList()
        }
      })
    },
    copy (n, text) {
      const input = document.getElementById(n)
      input.value = text
      input.select()
      if (text !== '') {
        document.execCommand('copy')
        this.$notify.success({
          title: '成功',
          message: '复制成功',
          duration: 500
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
width: 8px;
height: 8px;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
background-color: #ddd;
border-radius: 3px;
}
</style>
<style lang="less" scoped>
.refund {
  padding: 26px 0px 42px;
  box-sizing: border-box;
  textarea{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }
  .title{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    margin-bottom: 36px;
    span:first-child{
      color: #333333;
      font-size: 20px;
      font-weight: 500;
    }
    span:last-child{
      cursor: pointer;
      color: #3775F6;
      font-size: 14px;
    }
  }
  .table-box{
    padding: 20px 20px 100px 20px;
    box-shadow: 0px 0px 6px 1px rgba(48,78,86,0.1600);
    // min-height: calc(100% - 100px);
    min-height: 620px;
    position: relative;
    .row{
      margin: 0;
      .el-input{
        width: 358px;
        /deep/.el-input__inner{
          border-radius: 2px 0px 0px 2px;
          border-right: 0;
        }
        /deep/.el-input__inner:focus{
          border-color: #3775F6;
        }
      }
      .el-button{
        position: relative;
        left: -2px;
        width: 62px;
        border-radius:0px 2px 2px 0px;
        background: #3775F6;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .el-pagination{
      position: absolute;
      bottom: 20px;
    }
    .el-table{
      margin-top: 20px;
      /deep/.hit{
        width: 55px;
        height: 33px;
        background: #3775F6;
        border-radius: 8px 8px 8px 8px;
      }
      /deep/.refuse{
        width: 55px;
        height: 33px;
        background: #F56C6C;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .popout{
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    .hit{
      height: 247px;
    }
    .popout_box{
      margin-top: 260px;
      padding: 24px 32px;
      width: 340px;
      height: 211px;
      border-radius: 15px;
      background-color: #FFFFFF;
      .popout_title{
        margin-bottom: 23px;
        color: #333333;
        font-weight: bold;
        span{
          font-size: 16px;
          color: #333636;
        }
      }
      .popout_body{
        height: 120px;
        position: relative;
        p{
          font-size: 14px;
          padding: 0 13px;
          margin-top: 10px;
          color: #999999;
        }
        .btns{
          position: absolute;
          width: 100%;
          bottom: 0px;
          left: 0;
          padding-top: 14px;
          text-align: center;
          .back{
            color: #3775F6;
            width: 76px;
            border: 1px solid #3775F6;
            margin: 0 18px;
          }
          .sure{
            width: 76px;
            background: #3775F6;
            margin: 0 18px;
          }
        }
      }
    }
    .popout_box.hit {
      height: 267px;
      .popout_body{
        height: 166px;
        .errtip{
          color: #E60606;
          padding-top: 10px;
          padding-left: 13px;
          font-size: 14px;
          height: 31px;
        }
      }
    }
  }
}
</style>
